// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".pwa-widget__header{background-color:#000;height:48px;width:100%;z-index:200}.pwa-widget__header .content{align-items:center;display:flex;gap:10px;height:100%;justify-content:center;margin:0 auto;max-width:1280px;padding:0 100px}.pwa-widget__header .content .pwa-button{background-color:var(--button-background-color);border-radius:2px;color:#fff;cursor:pointer;padding:4px 10px}.pwa-widget__header.is-mobile .content{padding:0 20px;width:100%}.pwa-widget__header.is-mobile .content .describe{width:-moz-fit-content;width:fit-content}.pwa-widget__header.is-mobile .content .describe .ql-editor>p{overflow:hidden;text-align:center;text-overflow:ellipsis;white-space:nowrap}.pwa-widget__header.is-mobile .content .pwa-button{padding:2px 10px}.pwa-widget__drawer{background-color:#fff;border-top:1px solid #dedede;padding:40px 40px 0;position:fixed;z-index:200}.pwa-widget__drawer.is-mobile{bottom:0;left:0;transform:none;width:100%}.pwa-widget__drawer.is-edit.is-mobile{bottom:0;left:calc(50% + 12px);transform:translate(-50%);width:375px}.pwa-widget__drawer .el-icon-close{color:#000;cursor:pointer;font-size:18px;position:absolute;right:20px;top:20px}.pwa-widget__drawer .content{padding-bottom:60px}.pwa-widget__drawer .content .cover-btn{left:50%;position:absolute!important;top:50%;transform:translate(-50%,-50%)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"siteFontFamily": "system-ui,sans-serif,math,ui-rounded,ui-monospce"
};
module.exports = ___CSS_LOADER_EXPORT___;
